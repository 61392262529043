import React from 'react';
import {checkData} from "../../../helper";
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from "react-html-parser";


function Toprated({data}) {
    return (

        <section className="tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="Top-Rated US">
                            <h2>{checkData(data, 'top_rated_company_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div>{HtmlParser(checkData(data, 'top_rated_company_description'))}</div>
                    </Col>
                </Row>
            </Container>
        </section>


    );
}

export default Toprated;